
.card {
    background-color: white !important;
    border: none !important;
    border-radius: 0px !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important
}

.card:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important
}

.card>a {
    text-decoration: none !important;
    color: black !important
}

#blockitems {
    margin-right: 0px !important;
    margin-left: 0px !important
}

#icons_section {
    width: 30%;
    text-align: center;
    padding: 0% !important
}

#icons_section>i {
    font-size: 25px;
    color: black !important
}

#heading_section {
    width: 70%;
    line-height: 3.5vh;
}

#heading_section>h6 {
    margin-bottom: 0px !important;
    color: #990ae3 !important
}

#heading_section>p {
    font-size: 14px !important
}

.cssanimation {
    animation-duration: 1s;
    animation-fill-mode: both
}

.fadeInBottom {
    animation-name: fadeInBottom
}

.our-services {
    background-color: #f9f9f9;
  }

  .our-services > .container
  {
    padding: 5ch;
  }
  
  .our-services h2 {
    color: #333;
  }
  
  .our-services p {
    color: #666;
  }

  
  .our-services ul {
    list-style-type: none;
    padding: 0;
  }
  
  .our-services li {
    font-size: 16px;
    color: #333;
  }

  /* Contact us */
  .contact-details {
    padding-left: 0;
    margin-top: 20px;
  }
  
  .contact-details li {
    border: 1px solid #dee2e6; /* Light grey border for list items */
    border-radius: 8px; /* Rounded corners */
    padding: 15px;
    transition: box-shadow 0.3s ease;
  }
  
  .contact-details li:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
  }
  
  .contact-details li .font-weight-bold {
    font-size: 1.1rem;
  }
  
  .contact-details li .text-muted {
    font-size: 1rem;
    color: #6c757d;
  }
  
  .contact-link {
    text-decoration: none;
    color: #007bff; /* Bootstrap primary color */
    font-weight: 500;
  }
  
  .contact-link:hover {
    text-decoration: none;
  }
  
  .widget-toggle-button
  {
    margin-top: 10vh;
  }

  /* Features v1 */
  .feature-v1 .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .feature-v1 .slick-slide img {
    max-width: 100%;
  }
  
  .feature-v1 .pagination-item {
    padding: 10px;
  }
  
  .feature-v1 .heading {
    margin-bottom: 10px;
  }
  
  .feature-v1 .small {
    color: #007bff;
  }
  

  

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%)
    }

    to {
        opacity: 1
    }
}

.cssanimation2 {
    animation-duration: 2s;
    animation-fill-mode: both
}

.fadeInBottom2 {
    animation-name: fadeInBottom
}

@keyframes fadeInBottom2 {
    from {
        opacity: 0;
        transform: translateY(100%)
    }

    to {
        opacity: 1
    }
}

.cssanimation3 {
    animation-duration: 3s;
    animation-fill-mode: both
}

.fadeInBottom3 {
    animation-name: fadeInBottom
}

@keyframes fadeInBottom3 {
    from {
        opacity: 0;
        transform: translateY(100%)
    }

    to {
        opacity: 1
    }
}



/* Care Givers Info Styling */

.caregivers-info {
    padding: 10em;
    background-color: #f9f9f9;
  }
  
  .caregivers-info h2 {
    font-size: 2rem;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .caregivers-info p {
    font-size: 1.125rem;
    color: #34495e;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .caregivers-info h3 {
    font-size: 1.5rem;
    color: #2980b9;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .caregivers-info ul {
    list-style-type: disc;
    margin-left: 20px;
    padding-left: 20px;
  }
  
  .caregivers-info ul li {
    font-size: 1.125rem;
    color: #34495e;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .caregivers-info {
      padding: 15px;
    }
  
    .caregivers-info h2 {
      font-size: 1.75rem;
    }
  
    .caregivers-info h3 {
      font-size: 1.375rem;
    }
  
    .caregivers-info p,
    .caregivers-info ul li {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .caregivers-info {
      padding: 10px;
    }
  
    .caregivers-info h2 {
      font-size: 1.5rem;
    }
  
    .caregivers-info h3 {
      font-size: 1.25rem;
    }
  
    .caregivers-info p,
    .caregivers-info ul li {
      font-size: 0.95rem;
    }
  }  


  /* Testimonials styling */
  .site-section {
    position: relative;
  }
  
  .testimonial-slider {
    position: relative;
    overflow: hidden;
    padding: 20px;
  }
  
  .testimonial {
    padding: 20px;
  }
  
  .testimonial img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .testimonial blockquote {
    margin: 0;
  }
  
  .quote {
    font-style: italic;
  }
  
  .author {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .slider-button {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }

  /* Pricing styling */
  .pricing-section {
    padding: 40px 0;
  }
  
  .pricing-section .heading {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .pricing-section p {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
  }
  
  .pricing-section .btn-primary {
    background-color: #0f2946;
    border: none;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
  }
  
  /* Footer styling */
  .kilimanjaro_area {
    position: relative;
    z-index: 1;
}

.foo_top_header_one {
    background-color: #0f2946;
    color: #fff;
}

.section_padding_100_70 {
    padding-top: 100px;
    padding-bottom: 70px;
}

.kilimanjaro_part {
  margin-bottom: 30px;
}

.foo_top_header_one .kilimanjaro_part > h5 {
  color: #ffffff; /* White text for good contrast */
}

.kilimanjaro_part h4, .kilimanjaro_part h5 {
  margin-bottom: 30px;
  color: #ffffff; /* White text for good contrast */
}

.kilimanjaro_single_contact_info > p, 
.kilimanjaro_single_contact_info > h5, 
.kilimanjaro_blog_area > a, 
.foo_top_header_one .kilimanjaro_part > p {
  color: rgba(255, 255, 255, 0.8); /* Slightly stronger white for readability */
}

p, ul li, ol li {
  font-weight: 300;
  color: #ffffff; /* White text for good contrast */
}

ul {
  margin: 0;
  padding: 0;
}

.kilimanjaro_widget > li {
  display: inline-block;
}

.kilimanjaro_widget a {
  border: 1px solid #ffffff; /* White border for better visibility */
  border-radius: 6px;
  color: #ffffff; /* White text for good contrast */
  display: inline-block;
  font-size: 13px;
  margin-bottom: 4px;
  padding: 7px 12px;
}

.kilimanjaro_links a {
  border-bottom: 1px solid #ffffff; /* White underline for visibility */
  color: rgba(255, 255, 255, 0.8); /* Slightly stronger white for readability */
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
  padding-bottom: 10px;
}

.kilimanjaro_links li {
  list-style: none;
}

.kilimanjaro_social_links > li {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 5px;
}

.kilimanjaro_social_links a {
  border: 1px solid #ffffff; /* White border for better visibility */
  border-radius: 6px;
  color: #ffffff; /* White text for good contrast */
  display: inline-block;
  font-size: 13px;
  margin-bottom: 3px;
  padding: 7px 12px;
}

.kilimanjaro_blog_area .kilimanjaro_date {
  color: #ffcc00; /* Gold color for good contrast with blue background */
  font-size: 13px;
  margin-bottom: 5px;
}

.kilimanjaro_blog_area > p {
  color: rgba(255, 255, 255, 0.8); /* Slightly stronger white for readability */
}

.kilimanjaro_bottom_header_one {
  background-color: rgb(7, 28, 51); /* Updated background color */
  color: #ffffff; /* White text for good contrast */
}

.kilimanjaro_bottom_header_one p {
  margin: 0;
  padding: 10px 0;
  font-size: 14px;
  color: #ffffff; /* White text for good contrast */
}

/* Default paragraph styling remains unchanged */
p {
  color: initial; /* Keeps the original color for non-highlighted paragraphs */
}


/* Services Card */
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-img-top {
  width: 100%;
  height: auto;
}

.card-body {
  padding: 15px;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  color: #555;
}

.services {
  background-color: #f9f9f9;
}

.services .container {
  max-width: 1200px;
}

.services .display-4 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.services .lead {
  font-size: 1.25rem;
}


/* Styles for the circular icon container */
.icon-container {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px; /* Adjust size as needed */
  height: 80px; /* Adjust size as needed */
  background-color: #fff; /* Background color of the circle */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Adds shadow */
}

/* Icon inside the circle */
.icon-container i {
  font-size: 36px; /* Adjust icon size as needed */
  color: #333; /* Icon color */
}
