/* Base */
html {
  overflow-x: hidden;
}

body {
  line-height: 1.7;
  color: gray;
  font-weight: 400;
  font-size: 1rem;
}

::-moz-selection {
  background: #000;
  color: #fff;
}

::selection {
  background: #000;
  color: #fff;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "Playfair Display", serif;
}

.border-2 {
  border-width: 2px;
}

.text-black {
  color: #000 !important;
}

.bg-black {
  background: #000 !important;
}

.color-black-opacity-5 {
  color: rgba(0, 0, 0, 0.5);
}

.color-white-opacity-5 {
  color: rgba(255, 255, 255, 0.5);
}

body:after {
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.offcanvas-menu {
  position: relative;
}
.offcanvas-menu:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.2);
}

.btn {
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 10px 30px;
}
.btn:hover,
.btn:active,
.btn:focus {
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn.btn-primary {
  background: #0f2946;
  border-color: #0f2946;
  color: #fff;
}
.btn.btn-primary:hover {
  background: #0f2946;
  border-color: #0f2946;
  color: #fff;
}
.btn.btn-primary.btn-black--hover:hover {
  background: #666666;
  color: #fff;
}
.btn.btn-white {
  border-color: #fff;
  color: #0f2946;
  background: #fff;
}
.btn.btn-white:hover {
  background: transparent;
  border-color: #fff;
  color: #fff;
}
.btn.btn-outline-white {
  border-color: #fff;
  color: #fff;
}
.btn.btn-outline-white:hover {
  background: #fff;
  color: #0f2946;
}

.line-height-1 {
  line-height: 1 !important;
}

.bg-black {
  background: #000;
}

.form-control {
  height: 43px;
  border-radius: 4px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form-control:active,
.form-control:focus {
  border-color: #0f2946;
}
.form-control:hover,
.form-control:active,
.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.site-section {
  padding: 2.5em 0;
}
@media (min-width: 768px) {
  .site-section {
    padding: 8em 0;
  }
}
.site-section.site-section-sm {
  padding: 4em 0;
}

.site-section-heading {
  padding-bottom: 20px;
  margin-bottom: 0px;
  position: relative;
  font-size: 2.5rem;
}
@media (min-width: 768px) {
  .site-section-heading {
    font-size: 3rem;
  }
}

.site-footer {
  padding: 4em 0;
}
@media (min-width: 768px) {
  .site-footer {
    padding: 8em 0;
  }
}
.site-footer .border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.site-footer .social a {
  text-decoration: none;
  cursor: pointer;
}
.site-footer p {
  color: rgba(0, 0, 0, 0.7);
}
.site-footer h2,
.site-footer h3,
.site-footer h4,
.site-footer h5 {
  color: #000;
}
.site-footer a {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: underline;
}
.site-footer a:hover {
  color: black;
}
.site-footer ul li {
  margin-bottom: 10px;
}
.site-footer .footer-heading {
  font-size: 16px;
  color: #000;
}

.bg-text-line {
  display: inline;
  background: #000;
  -webkit-box-shadow: 20px 0 0 #000, -20px 0 0 #000;
  box-shadow: 20px 0 0 #000, -20px 0 0 #000;
}

.text-black-opacity-05 {
  color: rgba(0, 0, 0, 0.5);
}

.text-black-opacity-05 {
  color: rgba(0, 0, 0, 0.5);
}

.hover-bg-enlarge {
  overflow: hidden;
  position: relative;
}
@media (max-width: 991.98px) {
  .hover-bg-enlarge {
    height: auto !important;
  }
}
.hover-bg-enlarge > div {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.8s all ease-in-out;
  -o-transition: 0.8s all ease-in-out;
  transition: 0.8s all ease-in-out;
}
.hover-bg-enlarge:hover > div,
.hover-bg-enlarge:focus > div,
.hover-bg-enlarge:active > div {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
@media (max-width: 991.98px) {
  .hover-bg-enlarge .bg-image-md-height {
    height: 300px !important;
  }
}

.bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.bg-image.overlay {
  position: relative;
}
.bg-image.overlay:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.bg-image > .container {
  position: relative;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .img-md-fluid {
    max-width: 100%;
  }
}

@media (max-width: 991.98px) {
  .display-1,
  .display-3 {
    font-size: 3rem;
  }
}

.play-single-big {
  width: 90px;
  height: 90px;
  display: inline-block;
  border: 2px solid #fff;
  color: #fff !important;
  border-radius: 50%;
  position: relative;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.play-single-big > span {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-40%, -50%);
  -ms-transform: translate(-40%, -50%);
  transform: translate(-40%, -50%);
}
.play-single-big:hover {
  width: 120px;
  height: 120px;
}

.overlap-to-top {
  margin-top: -150px;
}

.ul-check {
  margin-bottom: 50px;
}
.ul-check li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  line-height: 1.5;
}
.ul-check li:before {
  left: 0;
  font-size: 20px;
  top: -0.3rem;
  font-family: "icomoon";
  content: "\e5ca";
  position: absolute;
}
.ul-check.white li:before {
  color: #fff;
}
.ul-check.success li:before {
  color: #71bc42;
}
.ul-check.primary li:before {
  color: #0f2946;
}
.ul-check li.remove {
  text-decoration: line-through;
  color: #dee2e6;
}
.ul-check li.remove:before {
  color: #dee2e6;
}

.select-wrap,
.wrap-icon {
  position: relative;
}
.select-wrap .icon,
.wrap-icon .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 22px;
}
.select-wrap select,
.wrap-icon select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}

/* Navbar */
.site-logo {
  font-size: 20px;
  font-weight: bold;
}

.site-navbar {
  margin-bottom: 0px;
  z-index: 19999;
  position: absolute;
  top: 0;
  width: 100%;
  color: #fff;
}

/* Basic styles for the header */
.site-navbar {
  transition: background-color 0.3s, color 0.3s;
}

.site-navbar.scrolled {
  background-color: white; /* Background color when scrolled */
  color: black; /* Text color when scrolled */
}

.site-navbar.scrolled .nav-link {
  color: black; /* Link color when scrolled */
}

.site-navbar.scrolled .site-logo a {
  color: black; /* Logo color when scrolled */
}

/* Mobile Menu */
.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 998;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.mobile-menu.open {
  display: block;
}

.mobile-menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 60px; /* Adjust if necessary to account for header height */
}

.mobile-menu-list .nav-link {
  display: block;
  padding: 10px 20px;
  color: black;
  text-decoration: none;
  font-size: 1.2em;
}

.mobile-menu-list .nav-link:hover {
  background: #f0f0f0;
}

.mobile-menu-list .has-children > .nav-link {
  position: relative;
}

.mobile-menu-list .has-children > .nav-link:after {
  content: '\f078'; /* FontAwesome caret down */
  font-family: 'FontAwesome';
  position: absolute;
  right: 20px;
}

.mobile-menu-list .has-children.open > .nav-link:after {
  content: '\f077'; /* FontAwesome caret up */
}

.mobile-menu-list .dropdown {
  display: none;
  padding-left: 20px;
}

.mobile-menu-list .dropdown.open {
  display: block;
}

/* Updated styles for mobile menu toggle button */
.site-menu-toggle {
  display: block;
  padding: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 999;
}

.site-menu-toggle .icon-menu {
  color: black;
  font-size: 1.5em;
}

.site-menu-toggle.open .icon-menu:before {
  content: '\f00d'; /* FontAwesome close icon */
}

.site-menu-toggle.open .icon-menu {
  color: black; /* Change color when open */
}



@media (min-width: 1030px)
{
  .site-menu-toggle {
    display: none; /* Hide on medium and larger screens */
  }
}


/* Additional styling for better appearance */
.site-menu-toggle {
  background-color: transparent;
  border-radius: 5px;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.site-menu-toggle:hover {
  background-color: white;
}


/* Other styles remain unchanged */

.site-navbar .site-logo {
  position: relative;
  left: 0;
}
.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
}
.site-navbar .site-navigation .site-menu .active {
  color: #0f2946;
  display: inline-block;
  padding: 20px 20px;
}
.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  display: inline-block;
}
.site-navbar .site-navigation .site-menu > li {
  display: inline-block;
}
.site-navbar .site-navigation .site-menu > li > a {
  padding: 20px 20px;
  color: #000;
  display: inline-block;
  text-decoration: none !important;
}
.site-navbar .site-navigation .site-menu > li > a:hover {
  color: #0f2946;
}
.site-navbar .site-navigation .site-menu > li.social > .nav-link {
  top: 20px;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 10px;
  border: 1px solid #efefef;
  border-radius: 50%;
}
.site-navbar .site-navigation .site-menu > li.social > a span {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.site-navbar .site-navigation .site-menu > li.social > a:hover {
  color: #0f2946;
}
.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}
.site-navbar .site-navigation .site-menu .has-children > a {
  position: relative;
  padding-right: 20px;
}
.site-navbar .site-navigation .site-menu .has-children > a:before {
  position: absolute;
  content: "\e313";
  font-size: 16px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "icomoon";
}
.site-navbar .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  position: absolute;
  width: 25vw;
  text-align: left;
  border-top: 2px solid #0f2946;
  -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  padding: 0px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: #fff;
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: #000;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown .active {
  color: #0f2946 !important;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown > li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 210px;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
  padding: 9px 20px;
  display: block;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li
  > a:hover {
  background: #eff1f3;
  color: #000;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > a:before {
  content: "\e315";
  right: 20px;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > .dropdown,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > ul {
  left: 100%;
  top: 0;
}
.site-navbar .site-navigation .site-menu .has-children:hover > a,
.site-navbar .site-navigation .site-menu .has-children:focus > a,
.site-navbar .site-navigation .site-menu .has-children:active > a {
  color: #0f2946;
}
.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}
.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}

.site-navbar.scrolled {
  position: sticky;
  background-color: white; /* Background color when scrolled */
  color: black; /* Text color when scrolled */
}

.site-navbar.scrolled .nav-link {
  color: black; /* Link color when scrolled */
}

/* Ensure that the logo and other elements adjust color if necessary */
.site-navbar.scrolled .site-logo a {
  color: black; /* Logo color when scrolled */
}

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  height: calc(100vh);
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.offcanvas-menu .site-mobile-menu {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}
.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  margin-left: 0px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}
.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}
.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
.site-mobile-menu .site-nav-wrap a {
  padding: 10px 20px;
  display: block;
  position: relative;
  color: #212529;
}
.site-mobile-menu .site-nav-wrap a:hover {
  color: #0f2946;
}
.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}
.site-mobile-menu .site-nav-wrap li .active {
  color: #0f2946;
}
.site-mobile-menu .site-nav-wrap .social {
  display: inline-block;
}
.site-mobile-menu .site-nav-wrap .social:hover {
  color: #0f2946;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
  background: #f8f9fa;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  font-family: "icomoon";
  content: "\f078";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-180deg);
  -ms-transform: translate(-50%, -50%) rotate(-180deg);
  transform: translate(-50%, -50%) rotate(-180deg);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.site-mobile-menu .site-nav-wrap > li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
.site-mobile-menu .site-nav-wrap > li.social {
  float: none !important;
  width: auto !important;
}
.site-mobile-menu .site-nav-wrap > li > a {
  padding-left: 20px;
  font-size: 20px;
}
.site-mobile-menu .site-nav-wrap > li > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.site-mobile-menu .site-nav-wrap > li > ul > li {
  display: block;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > a {
  padding-left: 40px;
  font-size: 16px;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
  padding: 0;
  margin: 0;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
  display: block;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
  font-size: 16px;
  padding-left: 60px;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] {
  float: left;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 5em;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] > li {
  width: auto;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] > li:first-child a {
  padding-left: 15px !important;
}

.sticky-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
}
.sticky-wrapper .site-navbar {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.sticky-wrapper .site-navbar .site-menu-toggle {
  color: #fff;
}
.sticky-wrapper .site-navbar .site-logo a {
  color: #fff;
}
.sticky-wrapper .site-navbar .site-menu > li > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.sticky-wrapper .site-navbar .site-menu > li > a:hover,
.sticky-wrapper .site-navbar .site-menu > li > a.active {
  color: #fff !important;
}
.sticky-wrapper.is-sticky .site-navbar {
  background: #fff;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.1);
}
.sticky-wrapper.is-sticky .site-navbar .site-menu-toggle {
  color: #000;
}
.sticky-wrapper.is-sticky .site-navbar .site-logo a {
  color: #000;
}
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a {
  color: #000 !important;
}
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a:hover,
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a.active {
  color: #0f2946 !important;
}

/* Blocks */
.slide-item,
.cover {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.slide-item.overlay,
.cover.overlay {
  position: relative;
}
.slide-item.overlay:before,
.cover.overlay:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
}
.slide-item > .container,
.cover > .container {
  position: relative;
  z-index: 2;
}
.slide-item,
.slide-item > .container > .row,
.cover,
.cover > .container > .row {
  height: 20vh;
  min-height: 500px;
}
.slide-item .heading,
.cover .heading {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
}

/* Ensure the parent container is positioned */
.parent-container {
  position: relative; /* Or 'absolute' if required */
}

/* Style for .feature-v1 */
.feature-v1 {
  position: relative; /* Absolute positioning to layer on top */
  z-index: 999; /* Ensure it appears above other elements */
  width: 90%; /* Adjust width as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust horizontal position */
  top: 100%; /* Adjust vertical position as needed */
  /* Optional: additional styles for visibility */
  padding: 20px;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow for better visibility */
  display: none;
}

.feature-v2
{
  position: absolute; /* Absolute positioning to layer on top */
  z-index: 999; /* Ensure it appears above other elements */
  width: 90%; /* Adjust width as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust horizontal position */
  top: 99vh; /* Adjust vertical position as needed */
  /* Optional: additional styles for visibility */
  padding: 20px;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow for better visibility */
  display: block;
}

.feature-v2 .align-items-center{
  background-color: #f0f1f3;
  padding: 2ch;
}

@media (max-width: 990px)
{
  .feature-v2{
    top: 85vh;
  }
}

@media (max-width: 1024px)
{
  .feature-v2{
    top: 90vh;
  }
}
@media (max-width: 1440px)
{
  .feature-v2{
    top: 85vh;
  }
}

/* components/NotFound.css */
.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #f06, #f9a);
  color: #fff;
  text-align: center;
}

.not-found-content {
  max-width: 600px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}

.not-found h1 {
  font-size: 120px;
  margin: 0;
  font-weight: bold;
}

.not-found h2 {
  font-size: 36px;
  margin: 10px 0;
  font-weight: 300;
}

.not-found p {
  font-size: 18px;
  margin-bottom: 20px;
}

.not-found .btn {
  text-decoration: none;
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #0f2946;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.not-found .btn:hover {
  background-color: #0f2946;
}


/* Responsive adjustments */
@media (max-width: 1200px) {
  .feature-v1 {
    width: 80%; /* Adjust width for larger tablets and smaller desktops */
    top: 50%; /* Adjust vertical position for larger screens */
  }
}

@media (max-width: 992px) {
  .feature-v1 {
    width: 85%; /* Adjust width for tablets and small desktops */
    top: 65%; /* Adjust vertical position for tablets */
  }
}

@media (max-width: 768px) {
  .feature-v1 {
    display: block;
    width: 90%; /* Adjust width for tablets in portrait mode */
    top: 60%; /* Adjust vertical position for tablets in portrait mode */
    padding: 15px; /* Adjust padding for smaller screens */
  }
  .feature-v2{
    display: none;
  }
}

@media (max-width: 576px) {
  .feature-v1 {
    width: 95%; /* Adjust width for mobile devices */
    top: 65%; /* Adjust vertical position for mobile devices */
    padding: 10px; /* Adjust padding for mobile devices */
  }
}


.feature-v1 .pagination-item {
  padding: 30px;
  width: 33.333%;
  background: #f8f9fa;
}
@media (max-width: 991.98px) {
  .feature-v1 .pagination-item {
    width: 100%;
  }
}
.feature-v1 .pagination-item.active {
  background: #fff;
  position: relative;
}
.feature-v1 .pagination-item.active:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  margin-top: -3px;
  background: #0f2946;
}
.feature-v1 .pagination-item > .icon-wrap, .feature-v2 .pagination-item > .icon-wrap {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  margin-right: 20px;
}
.feature-v1 .pagination-item > .icon-wrap img,
.feature-v2 .pagination-item > .icon-wrap img {
  width: 90px;
}
.feature-v1 .pagination-item > div .subheading,.feature-v2 .pagination-item > div .subheading {
  font-size: 12px;
}
.feature-v1 .pagination-item > div .heading, .feature-v2 .pagination-item > div .heading {
  font-size: 16px;
  font-weight: bold;
}

.site-section {
  padding: 90px 0;
}

.service {
  background: #fff;
  position: relative;
  top: 0;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.service .service-inner {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-top: none;
  padding: 20px;
  text-align: center;
}
.service .service-inner h3 {
  font-size: 20px;
  color: #000;
  margin-bottom: 0;
}
.service .service-inner p {
  font-size: 14px;
  color: #aaa;
}
.service .service-inner *:last-child {
  margin-bottom: 0;
}
.service:hover {
  position: relative;
  top: -2px;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
}

.counter-wrap {
  text-align: center;
}
.counter-wrap .counter {
  font-size: 60px;
  color: #fff;
}
.counter-wrap .counter span {
  color: white;
}
.counter-wrap span {
  color: rgba(255, 255, 255, 0.5);
}

.custom-media {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}
.custom-media .text {
  background: #0f2946;
  padding: 20px;
}
.custom-media .text h3 {
  color: #fff;
  font-size: 20px;
}
.custom-media .img-bg {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  width: 100%;
}

.feature h3 {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
}

.feature .img-wrap {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  background: #fff;
}
.feature .img-wrap img {
  position: absolute;
  width: 36px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.testimonial {
  background: #fff;
  padding: 20px;
  border: 1px solid #f8f9fa;
}
.testimonial img {
  width: 80px;
  border-radius: 50%;
  margin-bottom: 30px;
  margin-top: -50px;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
}
.testimonial blockquote {
  font-size: 18px;
  font-family: "Playfair Display", serif;
  font-style: italic;
}
.testimonial blockquote .quote {
  color: #000;
}
.testimonial blockquote .author {
  color: #aaa;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
}

.blog-entry {
  text-align: center;
}
.blog-entry h2 {
  font-size: 16px;
  font-weight: 700;
}
.blog-entry p {
  font-size: 14px;
}
.blog-entry .post-meta {
  background: #fff;
  padding: 2px 4px;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  text-align: center;
  font-size: 13px;
}
.blog-entry .post-meta [class*="icon-"] {
  color: #0f2946;
}
.blog-entry .post-meta > span {
  margin: 8px;
}

/*PRELOADING------------ */
#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7100;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loader {
  z-index: 7700;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Blog */
.sidebar-box {
  margin-bottom: 30px;
  padding: 25px;
  font-size: 15px;
  width: 100%;
  float: left;
  background: #fff;
}
.sidebar-box *:last-child {
  margin-bottom: 0;
}
.sidebar-box h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.categories li,
.sidelink li {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #dee2e6;
  list-style: none;
}
.categories li:last-child,
.sidelink li:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.categories li a,
.sidelink li a {
  display: block;
}
.categories li a span,
.sidelink li a span {
  position: absolute;
  right: 0;
  top: 0;
  color: #ccc;
}
.categories li.active a,
.sidelink li.active a {
  color: #000;
  font-style: italic;
}

.comment-form-wrap {
  clear: both;
}

.comment-list {
  padding: 0;
  margin: 0;
}
.comment-list .children {
  padding: 50px 0 0 40px;
  margin: 0;
  float: left;
  width: 100%;
}
.comment-list li {
  padding: 0;
  margin: 0 0 30px 0;
  float: left;
  width: 100%;
  clear: both;
  list-style: none;
}
.comment-list li .vcard {
  width: 80px;
  float: left;
}
.comment-list li .vcard img {
  width: 50px;
  border-radius: 50%;
}
.comment-list li .comment-body {
  float: right;
  width: calc(100% - 80px);
}
.comment-list li .comment-body h3 {
  font-size: 20px;
}
.comment-list li .comment-body .meta {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.1em;
  color: #ccc;
}
.comment-list li .comment-body .reply {
  padding: 5px 10px;
  background: #e6e6e6;
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
}
.comment-list li .comment-body .reply:hover {
  color: #000;
  background: #e3e3e3;
}

.search-form {
  background: #f7f7f7;
  padding: 10px;
}
.search-form .form-group {
  position: relative;
}
.search-form .form-group input {
  padding-right: 50px;
}
.search-form .icon {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.post-meta {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
.post-meta a {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

figure figcaption {
  margin-top: 0.5rem;
  font-style: italic;
  font-size: 0.8rem;
}

.card-title {
  font-size: 1.4rem;
  color: #000;
}

.team img {
  border-radius: 50%;
  margin-bottom: 20px;
}

.team h3 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.team .position {
  margin-bottom: 30px;
  color: #aaa;
}

.count-numbers, .elder-section
{
  background-color: #0f2946;
}

.text-black ul li a{
  color: #000 !important;
}

.text-white{
  color: #fff !important;
}