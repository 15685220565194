/* Base Styles */
.carousel-item {
  height: 100vh;
}

.carousel-item img {
  object-fit: cover;
  height: 100vh;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: left;
  padding: 20px;
}

.carousel-caption h1 {
  font-size: 3rem;
}

.carousel-caption p {
  font-size: 1.5rem;
}

.carousel-caption .btn {
  font-size: 1.2rem;
}

/* Coming Soon */
.sky_bg {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("images/pricing-caregiver-meal.jpg") no-repeat center center/cover;
  height: 100vh;
}

/* Vapour Animation */
.wrap_steam #steam {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: rgb(223, 212, 212);
  border-radius: 50%;
  opacity: 0;
  top: 500px;
  left: 280px;
  z-index: 9999;
}

.steam1 {
  animation: steam1 4.5s ease-out 1s infinite;
}

.steam3 {
  animation: steam1 3.5s ease-out 3s infinite;
}

.steam2,
.steam4,
.steam5 {
  animation: steam2 2s ease-out infinite;
}

@keyframes steam1 {
  0% {
      transform: translateY(0) translateX(0) scale(0.4);
      opacity: 0.25;
  }

  100% {
      transform: translateY(-20px) translateX(10px) scale(1);
      opacity: 0;
  }
}

@keyframes steam2 {
  0% {
      transform: translateY(0) translateX(0) scale(0.5);
      opacity: 0.25;
  }

  100% {
      transform: translateY(-30px) translateX(-10px) scale(1);
      opacity: 0;
  }
}

/* Main Content */
.hero {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: 'Dosis', sans-serif;
  color: #fff;
}

.hero h1 {
  font-size: 60px;
  text-shadow: 0 0 10px #fff;
  text-transform: uppercase;
}

.hero hr {
  width: 30%;
  margin: 30px auto;
  border: 1.5px solid #fff;
}

.hero p {
  font-size: 20px;
  margin-bottom: 50px;
}

#launch {
  font-size: 40px;
  word-spacing: 20px;
}

/* Contact Us Page Styles */
.contact {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-container {
  width: 80%;
  margin: 30px auto;
  max-width: 900px;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
  text-align: center;
}

.contact-container h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
}

.contact-container p {
  font-size: 18px;
  color: #666;
  margin-bottom: 15px;
  line-height: 1.6;
}

.contact-container .address,
.contact-container .phone,
.contact-container .hours {
  margin-bottom: 20px;
}

.contact-container .address span,
.contact-container .phone span,
.contact-container .hours span {
  font-weight: bold;
  color: #333;
}

/* A11yWidget Styling */
.a11y-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.a11y-widget-button {
  padding: 10px;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  border: none;
  background: #007bff;
  border-radius: 4px;
  transition: background 0.3s;
}

.a11y-widget-button:hover {
  background: #0056b3;
}

.a11y-widget-menu {
  display: none;
  position: absolute;
  bottom: 60px;
  right: 0;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 10px;
}

.a11y-widget-menu.open {
  display: block;
}

.a11y-widget-menu a {
  display: block;
  padding: 10px;
  color: #000;
  text-decoration: none;
}

.a11y-widget-menu a:hover {
  background: #f8f9fa;
}

/* Accessibility Widget Styling */
.accessibility-widget {
  position: fixed;
  right: 20px;
  top: 55%;
  transform: translateY(-50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.accessibility-widget button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accessibility-widget button:hover {
  background-color: #0056b3;
}

.widget-toggle-button {
  position: fixed;
  right: 20px;
  top: 100px; /* Adjust as needed */
  z-index: 10000;
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Large Text */
.large-text {
  font-size: 1.25em !important; /* Ensure it overrides other font sizes */
}

/* Serif Text */
.serif-text {
  font-family: 'Times New Roman', Times, serif !important; /* Force serif font */
}

/* High Contrast */
.high-contrast {
  background-color: #000 !important; /* Black background for high contrast */
  color: #fff !important; /* White text for high contrast */
}

/* Increased Line Height */
.increased-line-height {
  line-height: 1.8 !important; /* Ensure line height is applied */
}

/* Bold Text */
.bold-text {
  font-weight: bold !important; /* Ensure bold weight is applied */
}

li > .text-black {
  color: #000 !important;
}

/* Responsive Adjustments */
@media only screen and (max-width: 998px) {
  .hero {
      top: 190px;
  }

  .hero p {
      margin-bottom: 10px;
  }

  .carousel-caption h1 {
      font-size: 2.5rem;
  }

  .carousel-caption p {
      font-size: 1.2rem;
  }

  .carousel-caption .btn {
      font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .hero h1 {
      font-size: 40px;
  }

  .hero p {
      font-size: 16px;
  }

  .contact-container {
      padding: 20px;
  }

  .contact-container h1 {
      font-size: 28px;
  }

  .contact-container p {
      font-size: 16px;
  }

  .carousel-caption h1 {
      font-size: 2rem;
  }

  .carousel-caption p {
      font-size: 1rem;
  }

  .carousel-caption .btn {
      font-size: 0.9rem;
  }
}

@media only screen and (max-width: 576px) {
  .hero h1 {
      font-size: 30px;
  }

  .hero p {
      font-size: 14px;
  }

  .contact-container {
      width: 90%;
      padding: 15px;
  }

  .contact-container h1 {
      font-size: 24px;
  }

  .contact-container p {
      font-size: 14px;
  }

  .carousel-caption h1 {
      font-size: 1.5rem;
  }

  .carousel-caption p {
      font-size: 0.9rem;
  }

  .carousel-caption .btn {
      font-size: 0.8rem;
  }
}